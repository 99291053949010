"use client";
import React, { useState, useEffect } from "react";
import { RefreshIcon } from '@heroicons/react/solid';
import { useProcessAuth } from '../hooks/useProcessAuth';
import { ForgotPassword } from './AuthForgotPassword';
import { ResetPassword } from './AuthResetPassword';
import { useHistory } from 'react-router-dom';

export const Auth: React.FC = () => {
  const [randomImage, setRandomImage] = useState("/top1.jpg");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const history = useHistory();

  if (showForgotPassword) {
    history.push('/forgot-password')
  }

  if (showResetPassword) {
    history.push('/reset-password')
  }

  const {
    pw,
    setPw,
    email,
    setEmail,
    isLogin,
    setIsLogin,
    registerMutation,
    loginMutation,
    processAuth,
  } = useProcessAuth();
  
  useEffect(() => {
    const imageNumber = Math.floor(Math.random() * 7) + 1;
    setRandomImage(`/top${imageNumber}.jpg`);
  }, []);

  if (registerMutation.isLoading || loginMutation.isLoading) {
    return (
      <div className="flex justify-center items-center flex-col min-h-screen">
        <h1 className="text-xl text-gray-600 font-mono">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row h-screen font-roboto">
        <div className="flex flex-1 items-center justify-center">
            <div className="w-[400px] p-10">
                <h1 className="text-4xl font-bold mb-6">HAUS-labo</h1>
                <form onSubmit={processAuth}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm mb-2">
                            メールアドレスもしくはユーザー名
                        </label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="name@domain.comもしくはuser name"
                            className="w-full p-2 border border-gray-300 rounded"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm mb-2">
                            パスワード
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full p-2 border border-gray-300 rounded"
                            onChange={(e) => setPw(e.target.value)}
                            value={pw}
                        />
                    </div>

                    <button
                        className="w-full p-2 bg-blue-500 text-white rounded"
                        disabled={!email || !pw}
                        type="submit"
                    >
                        {isLogin ? 'ログイン' : 'サインアップ'}
                    </button>
                </form>
                <div className="mt-4 text-sm">
                    <a href="#" className="text-blue-500" onClick={() => setShowForgotPassword(true)}>
                        パスワードを忘れた方は こちらへ
                    </a>
                    <p className="text-gray-500 mt-2">
                        新規アカウント作成は コムハウスへお問い合わせください。
                    </p>
                </div>
                <div className="mt-8 text-sm">
                  <p className="text-gray-500 mb-2">マニュアルをダウンロード</p>
                  <ul className="list-disc pl-5">
                    <li><a href="/manual_toppage.pdf" download className="text-blue-500 hover:underline">トップページ</a></li>
                    <li><a href="/manual_login_new_project.pdf" download className="text-blue-500 hover:underline">ログインとムービーの新規作成</a></li>
                    <li><a href="/manual_create_movie.pdf" download className="text-blue-500 hover:underline">ムービーの生成</a></li>
                  </ul>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Auth;
